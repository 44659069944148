import React, { Component } from "react";
import axios from "axios"
import { defaultHeaders } from "../../util/Constants"
import { withRouter, Link } from "react-router-dom";
import { FaPlus, FaEye, FaFolderOpen, FaChevronLeft, FaChevronRight } from "react-icons/fa";
import LoadingContent from "../../components/LoadingContent";
import { ToastErrorGet, ToastError } from "../../components/Toast"
import Overlay from "../../components/Overlay";
import { getClientes, searchCliente } from "../../util/Clientes"
import InputSearch from "../../components/InputSearch"

import {
    Button,
    Card,
    Table,
    Container,
    Modal,
    Col,
    Row,
    Form
} from "react-bootstrap";

class Clientes extends Component {
    constructor() {
        super()
        this.state = {
            showView: false,
            showAdd: false,
            showEdit: false,
            isLoading: false,
            overlay: false,
            count: 0,
            next: "",
            previous: "",
            clientes: [],
            clienteSelected: {}
        }

        this.getData = this.getData.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.paginacao = this.paginacao.bind(this);
        this.pesquisa = this.pesquisa.bind(this);
    }

    componentWillMount() {
        this.getData()
    }

    getData() {
        this.setState({isLoading: true})
        getClientes((data, error) => {
            if (error) {
                ToastErrorGet()
            } else {
                this.setState({
                    clientes: data.results,
                    count: data.count,
                    next: data.next,
                    previous: data.previous,
                    isLoading: false
                })
            }
        });
    }

    handleChange(event) {
        let name = event.target.name;
        this.setState({
        [name]: event.target.value,
        });
    }

    paginacao(value) {
        if (value) {
            this.setState({ isLoading: true })
            let config = {
                headers: defaultHeaders()
            }

            let url = `https${value.slice(4)}`

            console.log(url)
            axios
                .get(url, config)
                .then(response => {
                    const data = response.data
                    this.setState({
                        clientes: data.results,
                        next: data.next,
                        previous: data.previous,
                        isLoading: false
                    })
                })
                .catch(error => {
                    ToastError(error)
                })
        }
    }
    
    pesquisa(value) {
        this.setState({isLoading: true})
        searchCliente(value, (error, data) => {
                if (error) {
                    ToastError(error)
                    this.setState({isLoading: false})
                } else {
                    this.setState({
                        clientes: data.results,
                        next: data.next,
                        previous: data.previous,
                        isLoading: false
                    })
                }
            });
    }

    render() {
        return (
            <div>
                <Container fluid>
                <Overlay loaded={this.state.overlay} />
                    <h6 className="text-secondary mb-4">Clientes</h6>
                    <Card className="shadow">
                        <Card.Header className="bg-danger">
                            <div className="d-flex justify-content-between align-items-center">
                                <h6 className="text-light m-0">LISTAGEM DE TÉCNICOS</h6>
                                <Link to={`/clientes/create`}><button className="btn btn-branco d-flex align-items-center"><FaPlus className="mr-2" /> Cadastrar</button></Link>
                            </div>
                        </Card.Header>
                        <Card.Body>
                            <Form.Group controlId="search" className="mb-3">
                                <InputSearch
                                    label="Pesquisar Cliente:"
                                    onChange={(search) => this.pesquisa(search)}
                                    placeholder="Pesquisar por: Código, Nome/Razão Social, CNPJ/CPF, Responsável"
                                />
                            </Form.Group>
                            <LoadingContent isLoading={this.state.isLoading}>
                                <Table hover responsive>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Nome/Razão Social</th>
                                            <th>CNPJ/CPF</th>
                                            <th>Responsável</th>
                                            <th>Ações</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {   
                                            this.state.clientes.map((cliente, key) =>{
                                                return(
                                                    <tr key={key}>
                                                        <td>{cliente.id}</td>
                                                        <td>{cliente.nome_razao_social}</td>
                                                        <td>{cliente.cnpj ? cliente.cnpj : cliente.cpf}</td>
                                                        <td>{cliente.responsavel}</td>
                                                        <td>
                                                            <Button variant="outline-primary" className="mr-2" size="sm" onClick={() => this.setState({showView: true, clienteSelected: cliente})}><FaEye /></Button>
                                                            <Link to={`/clientes/${cliente.id}`}><Button variant="outline-warning" className="mr-2" size="sm"><FaFolderOpen /></Button></Link>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </Table>
                            </LoadingContent>
                        </Card.Body>
                        <Card.Footer>
                            <div className="d-flex justify-content-between align-items-center">
                                <strong>Quantidade: {this.state.count}</strong>
                                <div>
                                    <Button
                                        disabled={!this.state.previous}
                                        variant="danger"
                                        className="mr-1" 
                                        onClick={() => this.paginacao(this.state.previous)}
                                    >
                                    <FaChevronLeft />
                                    </Button>
                                    <Button
                                        disabled={!this.state.next} 
                                        variant="danger" 
                                        onClick={() => this.paginacao(this.state.next)}
                                    >
                                        <FaChevronRight />
                                    </Button>
                                </div>
                            </div>
                        </Card.Footer>
                    </Card>
                </Container>
                {/* Modal Para Visualizacao */}
                <Modal show={this.state.showView} size="lg" onHide={() => this.setState({ showView: false })}>
                    <Modal.Header className="bg-danger" closeButton>
                        <Modal.Title className="text-light">Detalhe do Cliente: {this.state.clienteSelected.id}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="text-center">
                            <Row>
                                <Col xs={12} md={6}>
                                    <strong>Nome/Razão Social</strong>
                                    <p>{this.state.clienteSelected.nome_razao_social}</p>
                                    <strong>Tipo de Pessoa</strong>
                                    <p>{this.state.clienteSelected.tipo_pessoa}</p>
                                    <strong>CNPJ/CPF</strong>
                                    <p>{this.state.clienteSelected.tipo_pessoa === "PJ" ? this.state.clienteSelected.cnpj : this.state.clienteSelected.cpf}</p>
                                    <strong>Responsável</strong>
                                    <p>{this.state.clienteSelected.responsavel}</p>
                                </Col>
                                <Col xs={12} md={6}>
                                    <strong>Telefone 01</strong>
                                    <p>{this.state.clienteSelected.telefone1}</p>
                                    <strong>Telefone 02</strong>
                                    <p>{this.state.clienteSelected.telefone2}</p>
                                    <strong>UF</strong>
                                    <p>{this.state.clienteSelected.uf}</p>
                                    <strong>Site</strong>
                                    <p>{this.state.clienteSelected.site}</p>
                                    <strong>E-mail</strong>
                                    <p>{this.state.clienteSelected.email}</p>
                                </Col>
                            </Row>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.setState({ showView: false })}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}
export default withRouter(Clientes)
