import axios from "axios"
import { defaultHeaders } from "./Constants"

export function getDashboard(callBack) {
  let config = {
    headers: defaultHeaders()
  }

  let url = process.env.REACT_APP_API.split("api/v1/core/").join("dashboard/")
  
  axios
    .get(url, config)
    .then(response => {
      callBack(response.data, null)
    })
    .catch(error => {
      callBack(null, error)
    })
}
