import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Container, Card,  Accordion, Button, Col, Row, Modal } from "react-bootstrap";
import { FaAngleDown, FaTrash } from "react-icons/fa";
import { ToastError, ToastErrorGet, ToastSuccess } from "../../components/Toast";
import Overlay from "../../components/Overlay";
import LoadingContent from "../../components/LoadingContent";
import { getTicketById, deleteTickets } from "../../util/Tickets";
import { getMunicipioById } from "../../util/Municipios";
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import "../../assets/css/components/mapping.css"
class DetalheTicket extends Component {
    constructor() {
        super()
        this.state = {
            showDelete: false,
            isLoading: false,
            ticket: {
                tecnico:{},
            },
            cliente:{},
            chamados: [],
            overlay: false
        }

        this.getData = this.getData.bind(this);
        this.retornaMunicipio = this.retornaMunicipio.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
    }

    componentWillMount() {
        this.getData()
    }

    getData() {
        this.setState({isLoading: true})
        getTicketById(this.props.match.params.id, (data, error) => {
            if (error) {
                ToastErrorGet()
            } else {
                this.setState({
                    ticket: data,
                    cliente: data.cliente,
                    chamados: data.os_atendimento,
                    isLoading: false
                })
            }
        });
    }

    handleDelete(value){
        this.setState({overlay: true})
        deleteTickets(value, (error, data) => {
            if (error) {
                ToastError(error)
            } else {
                ToastSuccess(`Chamado foi excluido!`)
                this.setState({
                    overlay: false
                })
                this.props.history.push("/tickets")
            }
        });
    }

    formatDataTimeStamp(value){
        if(value){
            let date = value.slice(0,10)
            let arrayDate = date.split('-')
            let time = value.slice(11,16)
            date = `${arrayDate[2]}/${arrayDate[1]}/${arrayDate[0]} às ${time}`
            return date
        }else{
            return "Não definido"
        }
    }

    retornaMunicipio(value){
        getMunicipioById(value, (data,error) =>{
            if(error){
                ToastErrorGet()
            }else{
                console.log(data.nome)
            }
        })
    }

    render() {
        return (
            <div>
                <Container fluid>
                    <Overlay isLoading={this.state.overlay} />
                    <h6 className="text-secondary mb-4">Chamados</h6>
                    <Card className="shadow mb-4">
                        <Card.Header className="border-0 bg-light">
                            <div className="d-flex justify-content-between align-items-center">
                                <h6 className="text-vermelho-bold m-0">DETALHE DO CHAMADO #{this.state.ticket.id}</h6>
                                {!this.state.chamados.length > 0 ?(
                                <Button
                                    variant="danger"
                                    size="sm" 
                                    className="d-flex align-items-center"
                                    onClick={() => this.setState({ showDelete: true })}
                                >
                                    <FaTrash className="mr-2"/>
                                    <strong>Excluir Chamado</strong>
                                </Button>
                                ): ""}
                                
                            </div>
                        </Card.Header>
                        <Card.Body>
                            <LoadingContent isLoading={this.state.isLoading}>
                                <div className="card-detalhe mb-4">
                                    <p><strong>OS°:</strong> {this.state.ticket.os}</p>
                                    <p><strong>Técnico:</strong> {this.state.ticket.tecnico.nome}</p>
                                    <p><strong>Serviço:</strong> {this.state.ticket.servico}</p>
                                    <p><strong>Descrição:</strong> {this.state.ticket.descricao || "Sem Descrição"}</p>
                                    <p><strong>Data de criação:</strong> {this.state.ticket && this.formatDataTimeStamp(this.state.ticket.created_at)}</p>
                                </div>
                                <Accordion>
                                    <Card>
                                        <Accordion.Toggle as={Card.Header} eventKey="0">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <h6 className="text-vermelho-bold m-0">CLIENTE: {this.state.cliente.nome_razao_social}</h6>
                                                <Button variant="light" className="d-flex align-items-center"><FaAngleDown /></Button>
                                            </div>
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey="0">
                                            <Card.Body>
                                                <Row className="text-center">
                                                    <Col>
                                                        <p><strong>OS°:</strong> {this.state.cliente.tipo_pessoa}</p>
                                                        <p><strong>CPF/CNPJ:</strong> {this.state.cliente.cnpj ? this.state.cliente.cnpj : this.state.cliente.cpf}</p>
                                                        <p><strong>Responsável:</strong> {this.state.cliente.responsavel || "Valor não definido"}</p>
                                                        <p><strong>E-mail:</strong> {this.state.cliente.email || "Valor não definido"}</p>
                                                        <p><strong>Telefone 01:</strong> {this.state.cliente.telefone1 || "Valor não definido"}</p>
                                                        <p><strong>Telefone 02:</strong> {this.state.cliente.telefone2 || "Valor não definido"}</p>
                                                    </Col>
                                                    <Col>
                                                        <p><strong>Site:</strong> {this.state.cliente.site || "Valor não definido"}</p>
                                                        <p><strong>Logradouro:</strong> {this.state.cliente.logradouro || "Valor não definido"}</p>
                                                        <p><strong>Bairro:</strong> {this.state.cliente.bairro || "Valor não definido"}</p>
                                                        <p><strong>CEP:</strong> {this.state.cliente.cep || "Valor não definido"}</p>
                                                        <p><strong>Munícipio:</strong> {this.state.cliente.municipio}</p>
                                                        <p><strong>UF:</strong> {this.state.cliente.uf || "Valor não definido"}</p>
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                </Accordion>
                            </LoadingContent>
                        </Card.Body>
                    </Card>
                    <Card className="shadow">
                        <Card.Header className="border-0 bg-light">
                            <div className="d-flex justify-content-between align-items-center">
                                <h6 className="text-vermelho-bold m-0">Atendimentos: {this.state.chamados.length}</h6>
                            </div>
                        </Card.Header>
                        <Card.Body>
                            <LoadingContent isLoading={this.state.isLoading}>
                                <Accordion>
                                    {(this.state.chamados || []).map((chamado, key) => {
                                        return(
                                            <Card key={key}>
                                                <Accordion.Toggle as={Card.Header} eventKey="0">
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <h6 className="text-vermelho-bold m-0">{this.formatDataTimeStamp(chamado.atendimento_time)}</h6>
                                                        <Button variant="light" className="d-flex align-items-center"><FaAngleDown /></Button>
                                                    </div>
                                                </Accordion.Toggle>
                                                <Accordion.Collapse eventKey="0">
                                                    <Row>
                                                        <Col xs={12} md={5}>
                                                            <Card.Body>
                                                                <p><strong>Serviço: </strong>{chamado.servico || "Não definido"}</p>
                                                                <p><strong>Status: </strong>
                                                                {chamado.atendimento_status === "P" && "Pendente"}
                                                                {chamado.atendimento_status === "F" && "Finalizado"}
                                                                </p>
                                                            </Card.Body>
                                                        </Col>
                                                        <Col xs={12} md={7}>
                                                            {chamado && <div id="map">
                                                                <Map
                                                                    google={this.props.google}
                                                                    zoom={15}
                                                                    initialCenter={{
                                                                        lat: parseFloat(chamado.latitude),
                                                                        lng: parseFloat(chamado.longitude)
                                                                      }}
                                                                >
                                                                <Marker position={{
                                                                        lat: parseFloat(chamado.latitude),
                                                                        lng: parseFloat(chamado.longitude)
                                                                      }}
                                                                  />
                                                                </Map>
                                                            </div>
                                                            }
                                                        </Col>
                                                    </Row>
                                                </Accordion.Collapse>
                                            </Card>
                                        )
                                    })}
                                    
                                </Accordion>
                            </LoadingContent>
                        </Card.Body>
                    </Card>
                </Container>
                {/* Modal de Delete Chamado */}
                <Modal show={this.state.showDelete} onHide={() => this.setState({showDelete: false})}>
                    <Modal.Header className="bg-danger" closeButton>
                    <Modal.Title>
                        <h5 className="text-light m-0">Deseja deletar o chamado #{this.state.ticket.id}</h5>
                    </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Selecione "Excluir" abaixo se você deseja deletar o chamado atual.</Modal.Body>
                    <Modal.Footer>
                    <Button 
                        variant="secondary"
                        onClick={() => this.setState({showDelete: false})}
                    >
                        Cancelar
                    </Button>
                    <Button
                        variant="danger" 
                        className="d-flex align-items-center"
                        onClick={() => this.handleDelete(this.state.ticket.id)}
                    >
                        <FaTrash className="mr-2"/>
                        Excluir
                    </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

export default withRouter(GoogleApiWrapper(
    (props) => ({
      apiKey: 'AIzaSyDYR_Sfh7npG5OA1TLjY-P3teE1wDmYOyM',
    }
  ))(DetalheTicket))
