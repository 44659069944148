import React, { Component } from "react"
import Layout from "./Layout"

const LayoutWrapper = Children =>
  class AnimatedWrapper extends Component {

    render() {
      return (
        <Layout>
          <Children {...this.props} />
        </Layout>
      )
    }
  }
export default LayoutWrapper