import axios from "axios"
import { defaultHeaders } from "./Constants"
import { jsPDF } from "jspdf";
import 'jspdf-autotable'
import imgData from "../assets/img/logo.png"

export function getReportChamados(tecnico,dataInicial,dataFinal,callBack) {
  let config = {
    headers: defaultHeaders()
  }

  let url = process.env.REACT_APP_API.split("core/").join(`ticket/relatorio/chamado/?tecnico=${tecnico}&dataInicial=${dataInicial}&dataFinal=${dataFinal}`)
  
  axios
    .get(url, config)
    .then(response => {
      generatePDF(response.data)
      callBack(response.data, null)
    })
    .catch(error => {
      callBack(null, error)
    })
}

function formatDataTimeStamp(value){
  if(value){
      let date = value.slice(0,10)
      let arrayDate = date.split('-')
      date = `${arrayDate[2]}/${arrayDate[1]}/${arrayDate[0]}`
      return date
  }else{
      return "Não definido"
  }
}

function getData(data){
  let results = []

  results = (data || []).map( chamado => {
    return [
      chamado.os,
      chamado.cliente.nome_razao_social,
      chamado.tecnico.nome,
      formatDataTimeStamp(chamado.created_at),
      chamado.servico, 
    ]
  })

  return results;
}

function getTotalizador(data){
  return ((data || []).length).toString()
}

function generatePDF(data){

  jsPDF.autoTableSetDefaults({
    headStyles: { fillColor: [188, 36, 22] }
  })

  var doc = new jsPDF('p', 'pt');
  var startX=40;
  var startY=30;

  doc.setFontSize(40);
  doc.addImage(imgData, "JPEG", startX, startY, 180, 50);
  doc.setFontSize(15);
  doc.text("RELATÓRIO DE ATENDIMENTOS", 320, 60);
  doc.setFontSize(8);
  doc.text(`Total de Chamados: ${getTotalizador(data)}`, 450, 110);
  doc.autoTable({
    styles: { fontSize: 8 },
    // theme: 'grid',
    head: [['OS', 'CLIENTE', 'TÉCNICO', 'DATA', 'SERVIÇO']],
    body: getData(data),
    startY: 120,
  })
  // doc.output("dataurlnewwindow");
  doc.save('atendimentos.pdf')
}





