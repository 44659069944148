import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { FaEye, FaFolderOpen, FaPrint } from "react-icons/fa";
import LoadingContent from "../../components/LoadingContent";
import { ToastError, ToastErrorGet } from "../../components/Toast"
import Overlay from "../../components/Overlay";
import { getTickets, searchChamado, getPrintChamado } from "../../util/Tickets"
import InputSearch from "../../components/InputSearch"

import {
    Button,
    Card,
    Table,
    Container,
    Modal,
    Row,
    Col,
    Form
} from "react-bootstrap";

class Tickets extends Component {
    constructor() {
        super()
        this.state = {
            showView: false,
            isLoading: false,
            overlay: false,
            tickets: [],
            ticketSelected: {
                tecnico:{},
                cliente:{}
            }
        }

        this.getData = this.getData.bind(this);
    }

    componentWillMount() {
        this.getData()
    }

    getData() {
        this.setState({isLoading: true})
        getTickets((this.props.status || "novos"),(data, error) => {
            if (error) {
                ToastErrorGet()
            } else {
                this.setState({
                    tickets: data,
                    isLoading: false
                })
            }
        });
    }

    handleChange(event) {
        let name = event.target.name;
        this.setState({
        [name]: event.target.value,
        });
    }

    formatDataTimeStamp(value){
        if(value){
            let date = value.slice(0,10)
            let arrayDate = date.split('-')
            let time = value.slice(11,16)
            date = `${arrayDate[2]}/${arrayDate[1]}/${arrayDate[0]} às ${time}`
            return date
        }else{
            return "Não definido"
        }
    }

    pesquisa(value) {
        this.setState({isLoading: true})
        searchChamado(this.props.status,value, (error, data) => {
                if (error) {
                    ToastError(error)
                    this.setState({isLoading: false})
                } else {
                    this.setState({
                        tickets: data,
                        isLoading: false
                    })
                }
            });
    }

    gerarRelatorio(ticket){
        this.setState({ overlay: true })
        getPrintChamado(ticket, (data,error)=> {
            if(error){
                ToastErrorGet();
                this.setState({ overlay: false })
            }else{
                console.log(data);
                this.setState({ overlay: false })
            }
        })
    }

    render() {
        return (
            <div>
                <Container fluid>
                <Overlay loaded={this.state.overlay} />
                    <Card className="shadow">
                        <Card.Header className="border-0 bg-light">
                            <div className="d-flex justify-content-between align-items-center">
                                <h6 className="text-vermelho-bold m-0 text-uppercase">CHAMADOS {this.props.status}</h6>
                            </div>
                        </Card.Header>
                        <Card.Body>
                            <Form.Group controlId="search" className="mb-3">
                                <InputSearch
                                    label="Pesquisar Chamado:"
                                    onChange={(search) => this.pesquisa(search)}
                                    placeholder="Pesquisar por código da OS"
                                />
                            </Form.Group>
                            <LoadingContent isLoading={this.state.isLoading}>
                                <Table hover responsive>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>OS</th>
                                            <th>Serviço Solicitado</th>
                                            <th>Técnico</th>
                                            <th>Ações</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {   
                                            this.state.tickets > [] ?(
                                            this.state.tickets.map((ticket, key) =>{
                                                return(
                                                    <tr key={key}>
                                                        <td>{ticket.id}</td>
                                                        <td>{ticket.os}</td>
                                                        <td style={{ maxWidth: "500px" }}>{ticket.servico}</td>
                                                        <td>{ticket.tecnico.nome}</td>
                                                        <td>
                                                            <Button variant="outline-primary" className="mr-2" size="sm" onClick={() => this.setState({showView: true, ticketSelected: ticket})}><FaEye /></Button>
                                                            <Link to={`/tickets/${ticket.id}`}>
                                                                <Button
                                                                    variant="outline-warning"
                                                                    className="mr-2"
                                                                    size="sm"
                                                                >
                                                                    <FaFolderOpen />
                                                                </Button>
                                                            </Link>
                                                            <Button
                                                                variant="outline-success" 
                                                                size="sm"
                                                                onClick={() => this.gerarRelatorio(ticket.id)}
                                                            >
                                                                <FaPrint />
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                            ): (
                                                <tr className="text-center">
                                                    <td colspan="5">Nenhum Chamado Encontrado</td>
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                </Table>
                            </LoadingContent>
                        </Card.Body>
                    </Card>
                </Container>
                {/* Modal Para Visualizacao */}
                <Modal show={this.state.showView} size="lg" onHide={() => this.setState({ showView: false })}>
                    <Modal.Header className="bg-light" closeButton>
                        <h5 className="text-vermelho-bold">Detalhe do Chamado: {this.state.ticketSelected.id}</h5>
                    </Modal.Header>
                    <Modal.Body>
                        <Row >
                            <Col className="text-center" xs={12} md={6}>
                                <strong>Ordem de Serviço</strong>
                                <p>{this.state.ticketSelected.os}</p>
                                <strong>Técnico</strong>
                                <p>{this.state.ticketSelected.tecnico.nome}</p>
                                <strong>Cliente</strong>
                                <p>{this.state.ticketSelected.cliente.nome_razao_social}</p>
                                <strong>Data de criação</strong>
                                <p>{this.state.ticketSelected && this.formatDataTimeStamp(this.state.ticketSelected.created_at)}</p>
                            </Col>
                            <Col className="text-center" xs={12} md={6}>
                                <strong>Serviço</strong>
                                <p>{this.state.ticketSelected.servico}</p>
                                <strong>Descrição</strong>
                                <p>{this.state.ticketSelected.descricao || "Sem descrição"}</p>
                            </Col>
                            
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.setState({ showView: false })}>
                            Close
                        </Button>
                        <Link to={`/tickets/${this.state.ticketSelected.id}`}>
                            <Button className="mr-2">
                                Mais Informações
                            </Button>
                        </Link>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}
export default withRouter(Tickets)
