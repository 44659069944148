import React, { Component } from "react";
import Chart from "react-apexcharts";

class VacinacaoPorGrupo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      options: {
        chart: {
          type: 'area',
          stacked: false,
          id: "basic-bar",
          zoom: {
            type: 'x',
            enabled: true,
            autoScaleYaxis: true
          },
          toolbar: {
            autoSelected: 'zoom'
          }
        },
        xaxis: {
          categories: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho','Julho', 'Agosto', 'Setembro', 'Outubro','Novembro', 'Dezembro'],
        },
        colors: [this.props.color]
      },
      series: [
        {
          name: "Atendimentos",
          data: [10, 15, 30, 34, 0, 0, 0, 0, 0, 0, 0, 0]
        }
      ],
    };

  }

  tratamentoDeDados(value){
    let meses = [0,0,0,0,0,0,0,0,0,0,0,0]
    if(value){
      value.forEach(e => {
        let mes = parseInt(e.split('-')[1])
        meses[mes - 1]++
      });
    }
    return [{name: "Atendimentos",data: meses}]
  }

  render() {
    return (
      <div className="app">
        <div className="row">
          <div className="mixed-chart chart-100">
            <Chart
              options={this.state.options}
              series={this.tratamentoDeDados(this.props.data)}
              type={this.props.type}
              height={this.props.height}
              width={this.props.width}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default VacinacaoPorGrupo;