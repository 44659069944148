import React from "react";
import { Link } from 'react-router-dom'
import {FaChartLine, FaFile, FaLock, FaPlus, FaTicketAlt, FaUser, FaWrench} from 'react-icons/fa'
import Logo from '../assets/img/logo.png'
import '../assets/css/components/sidebar.css'

const Sidebar = ({show}) => {
    return (
        <div className={show ? 'side-nav active' : 'side-nav'}>
            <ul>
                <div className="d-flex justify-content-center mt-4">
                    <img src={Logo} width="220px" alt="Logo"/>
                </div>
                <hr className="border-top border-light"/>
                <li><Link to="/dashboard"><FaChartLine /> Dashboard</Link></li>
                <hr className="border-top border-danger"/>
                <li><Link to="/tickets/create"><FaPlus /> Registrar Chamado</Link></li>
                <li><Link to="/tickets"><FaTicketAlt /> Chamados</Link></li>
                <hr className="border-top border-danger"/>
                <li><Link to="/clientes"><FaUser />Clientes</Link></li>
                <hr className="border-top border-danger"/>
                <li><Link to="/tecnicos"><FaWrench /> Técnicos</Link></li>
                <li><Link to="/permissoes"><FaLock /> Permissões</Link></li>
                <hr className="border-top border-danger"/>
                <li><Link to="/relatorios"><FaFile />Relatórios</Link></li>                
            </ul>
        </div>
    )
}

export default Sidebar
