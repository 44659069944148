import React, { Component } from "react";
import { withRouter } from "react-router";
import LoadingContent from "../../components/LoadingContent";
import { ToastErrorGet } from "../../components/Toast"
import Overlay from "../../components/Overlay";
import { getUsers } from '../../util/auth'

import {
    Card,
    Table,
    Container,
} from "react-bootstrap";

class Tecnicos extends Component {
    constructor() {
        super()
        this.state = {
            showView: false,
            showAdd: false,
            isLoading: false,
            users: []            
        }

        this.getData = this.getData.bind(this);
    }

    componentWillMount() {
        this.getData()
    }

    getData() {
        this.setState({isLoading: true})
        getUsers((data, error) => {
            if (error) {
                ToastErrorGet()
            } else {
                let tecnicos = data.filter((tecnico)=> {
                    return tecnico.groups[0] === "tecnico"
                })
                this.setState({
                    users: tecnicos,
                    isLoading: false
                })
            }
        });
    }

    render() {
        return (
            <div>
                <Container fluid>
                <Overlay loaded={this.state.overlay} />
                    <h6 className="text-secondary mb-4">Permissões</h6>
                    <Card className="shadow">
                        <Card.Header className="border-0 bg-light">
                            <div className="d-flex justify-content-between align-items-center">
                                <h6 className="text-vermelho-bold m-0">TÉCNICOS REGISTRADOS</h6>
                            </div>
                        </Card.Header>
                        <Card.Body>
                            <LoadingContent isLoading={this.state.isLoading}>
                                <Table hover responsive>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Nome</th>
                                            <th>Email</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {   
                                            this.state.users.map((user, key) =>{
                                                return(
                                                    <tr key={key}>
                                                        <td>{user.id}</td>
                                                        <td>{user.nome}</td>
                                                        <td>{user.email}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </Table>
                            </LoadingContent>
                        </Card.Body>
                    </Card>
                </Container>
            </div>
        )
    }
}
export default withRouter(Tecnicos)
