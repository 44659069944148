import React, { Component } from "react";
import { withRouter } from "react-router";
import { FaBars, FaTimes, FaSignInAlt } from "react-icons/fa";
import {logout} from "../util/auth"

import {
    Button,
    Modal
} from "react-bootstrap";

import '../assets/css/components/header.css'

class Header extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showModal: false
        }

        this.logout = this.logout.bind(this);
    }

    logout(){
        logout()
        this.props.history.push('/login')
    }

    render() {
        return (
            <>
            <header className={ this.props.show ? 'cabecalho ativo' : 'cabecalho'}>
                <div className="d-flex justify-content-between align-items-center">
                    <div>
                        {this.props.show ? <FaTimes className="text-light font-30" onClick={() => this.props.executar(!this.props.show)}/> : <FaBars className="text-light font-30" onClick={() => this.props.executar(!this.props.show)}/>}
                        <h2>{process.env.REACT_APP_APP_NAME}</h2>
                    </div>
                    <div className="ml-4">
                        <button className="btn btn-branco d-flex align-items-center" onClick={() => this.setState({showModal: true})}>
                            <FaSignInAlt className="mr-2"/> SAIR
                        </button>
                    </div>
                </div>
            </header>
            <Modal show={this.state.showModal} onHide={() => this.setState({showModal: false})}>
                <Modal.Header closeButton>
                <Modal.Title>Deslogando...</Modal.Title>
                </Modal.Header>
                <Modal.Body>Selecione "Sair" abaixo se você deseja encerrar sua sessão atual.</Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={() => this.setState({showModal: false})}>
                    Cancelar
                </Button>
                <Button variant="danger" onClick={this.logout}>
                    Sair
                </Button>
                </Modal.Footer>
            </Modal>
            </>
        )
    }
}
export default withRouter(Header)

