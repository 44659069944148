import axios from "axios"

export function defaultHeaders() {
    var token = localStorage.token
    return {
      "Content-Type": "application/json",
      Authorization: "Token " + token,
      Accept: "application/json"
    }
  }

  export function makeRequestCreator() {
    var call;
    return function (url, callBack) {
      if (call) {
        call.cancel();
      }
      call = axios.CancelToken.source();
      let config = {
        headers: defaultHeaders(),
        data: { a: "a" },
        cancelToken: call.token
      }
      return axios
        .get(url, config)
        .then(response => {
          callBack(undefined, response.data)
        })
        .catch(error => {
          if (axios.isCancel(error)) {
            callBack(undefined, [])
          } else {
            callBack(error, undefined)
          }
  
        })
    }
  }