import React, { Component } from "react";
import Sidebar from "../Sidebar";
import Header from "../Header";

class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSide: true
    };

    this.showSidebar = this.showSidebar.bind(this)
  }

  componentWillMount(){
    if (window.innerWidth <= 1024) {
      this.setState({ showSide: false });
    }
  }

  showSidebar(value){
    this.setState({
      showSide: value
    })
  }

  render() {
    return (
      <div>
        <Sidebar show={this.state.showSide}/>
        <Header show={this.state.showSide} executar={this.showSidebar}/>
        <div className={ this.state.showSide ? 'main-active' : 'main'}>
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default Layout;
