import axios from "axios"
import { defaultHeaders, makeRequestCreator } from "./Constants"
import { jsPDF } from "jspdf";
import 'jspdf-autotable'
import imgData from "../assets/img/logo.png"


export function getTicketById(pk,callBack) {
  let config = {
    headers: defaultHeaders()
  }
  let url = process.env.REACT_APP_API.split("core/").join(`ticket/detalhe/${pk}`)
  
  axios
    .get(url, config)
    .then(response => {
      callBack(response.data, null)
    })
    .catch(error => {
      callBack(null, error)
    })
}

export function getTickets(status,callBack) {
  let config = {
    headers: defaultHeaders()
  }

  let url = process.env.REACT_APP_API.split("core/").join(`ticket/${status}/`)
  
  axios
    .get(url, config)
    .then(response => {
      callBack(response.data, null)
    })
    .catch(error => {
      callBack(null, error)
    })
}

export function postTickets(params, callBack) {
  let config = {
    headers: defaultHeaders()
  }
  let url = process.env.REACT_APP_API.split("core/").join("ticket/ticket/")
  axios
    .post(url, params,config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}

export function putTickets(id, params, callBack) {
  let config = {
    headers: defaultHeaders()
  }
  
  let url = process.env.REACT_APP_API.split("core/").join(`ticket/ticket/${id}/`)
  axios
    .put(url, params, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}

export function deleteTickets(id, callBack) {
  let config = {
    headers: defaultHeaders()
  }

  let url = process.env.REACT_APP_API.split("core/").join(`ticket/ticket/${id}/`);

  axios
    .delete(url, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}

const searchGet = makeRequestCreator()
export function searchChamado(status,searchText, callBack) {
  let url = process.env.REACT_APP_API.split("core/").join(`ticket/${status}/?search=${searchText}`)

  return searchGet(url, callBack)
}

function formatDataTimeStamp(value){
  if(value){
      let date = value.slice(0,10)
      let arrayDate = date.split('-')
      let time = value.slice(11,16)
      date = `${arrayDate[2]}/${arrayDate[1]}/${arrayDate[0]} às ${time}`
      return date
  }else{
      return "Não definido"
  }
}

function getData(data){
  let results = []
  results = (data.os_atendimento || []).map( atendimento => {
    return [
      data.os,
      data.tecnico.nome,
      atendimento.servico, 
      formatDataTimeStamp(atendimento.atendimento_time), 
      atendimento.atendimento_status === 'F' ? "Finalizado" : "Pendente"
    ]
  })

  return results;
}

export function getPrintChamado(pk,callBack) {
  let config = {
    headers: defaultHeaders()
  }

  let url = process.env.REACT_APP_API.split("core/").join(`ticket/detalhe/${pk}`)
  
  axios
    .get(url, config)
    .then(response => {
      console.log(response.data);
      generatePDF(response.data)
      callBack(response.data, null)
    })
    .catch(error => {
      callBack(null, error)
    })
}

function generatePDF(data = {}){

  jsPDF.autoTableSetDefaults({
    headStyles: { fillColor: [188, 36, 22] }
  })

  var doc = new jsPDF('p', 'pt');
  var startX=40;
  var startY=30;

  doc.setFontSize(40);
  doc.addImage(imgData, "JPEG", startX, startY, 180, 50);
  doc.setFontSize(15);
  doc.text(`DETALHE DO CHAMADO: ${data.id}`, 320, 60);
  doc.setFontSize(12);
  doc.text(`OS: ${data.os}`, 50, 120);
  doc.text(`Técnico: ${data.tecnico && data.tecnico.nome}`, 50, 140);
  doc.text(`Cliente: ${data.cliente && data.cliente.nome_razao_social}`, 50, 160);
  doc.text(`CNPJ/CPF do cliente: ${data.cliente.tipo_pessoa === "PJ" ? data.cliente.cnpj : data.cliente.cpf}`, 50, 180);
  doc.text(`Data de criação: ${formatDataTimeStamp(data.created_at)}`, 50, 200);
  var splitDescricao = doc.splitTextToSize(`Descrição do Chamado: ${data.descricao}`, 500);
  doc.text(50, 220, splitDescricao);
  doc.setFont(undefined, 'bold');
  doc.text(`Serviço Requisitado:`, 50, 260);
  doc.setFont(undefined, 'normal');
  var splitServico = doc.splitTextToSize(`${data.servico}`, 500);
  doc.text(50, 290, splitServico);
  doc.setFontSize(10);
  doc.text(`Atendimentos: ${data.os_atendimento.length}`, 40, 430);
  doc.autoTable({
    styles: { fontSize: 8 },
    head: [['OS', 'TÉCNICO', 'SERVIÇO PRESTADO', 'DATA', 'STATUS']],
    body: getData(data),
    startY: 440,
    showHead: 'firstPage',
  })
  // doc.output("dataurlnewwindow");
  doc.save('atendimentos.pdf')
}