import React, { Component } from "react"
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
  } from "react-router-dom"

import LayoutWrapper from './components/Layout/LayoutWrapper'
import Login from './views/Autenticacao/Login'
import Administradores from './views/Autenticacao/Administradores'
import Dashboard from './views/Dashboard'
import Clientes from './views/Clientes/Clientes'
import CreateClientes from './views/Clientes/CreateClientes'
import DetalheClientes from './views/Clientes/DetalheCliente'
import RegistrarTicket from './views/Ticket/RegistrarTicket'
import MenuTickets from './views/Ticket/MenuTicket'
import DetalheTicket from './views/Ticket/DetalheTicket'
import Tecnicos from './views/Autenticacao/Tecnicos'
import Relatorios from './views/Relatorios/MenuRelatorio';


function loggedIn() {
  if (!localStorage.token) {
      return false
  }
  return localStorage.token !== "null" && localStorage.token !== ""
}

  const PrivateRoute = ({component: Component, ...rest}) => (
    <Route
        {...rest}
        render={props =>
            loggedIn() ? (
                <Component {...props} />
            ) : (
                <Redirect
                    to={{
                        pathname: "/login",
                        state: {from: props.location}
                    }}
                />
            )
        }
    />
)

  const LoginRoute = ({component: Component, ...rest}) => (
    <Route
        {...rest}
        render={props =>
          loggedIn() ? (
                <Redirect
                    to={{
                        pathname: "/dashboard",
                        state: {from: props.location}
                    }}
                />
            ) : (
                <Component {...props} />
            )
        }
    />
)

class Routes extends Component {
    constructor(props){
        super(props)
        this.firebase = "Aqui vai o firebase"
    }

    render(){
        return(
            <Router>
                <Switch>
                    <LoginRoute exact path='/login' component={Login} />
                    <PrivateRoute exact path='/dashboard' component={LayoutWrapper(Dashboard)} />
                    <PrivateRoute exact path='/tickets' component={LayoutWrapper(MenuTickets)} />
                    <PrivateRoute exact path='/tickets/create' component={LayoutWrapper(RegistrarTicket)} />
                    <PrivateRoute exact path='/tickets/:id' component={LayoutWrapper(DetalheTicket)} />
                    <PrivateRoute exact path='/clientes' component={LayoutWrapper(Clientes)} />
                    <PrivateRoute exact path='/clientes/create' component={LayoutWrapper(CreateClientes)} />
                    <PrivateRoute exact path='/clientes/:id' component={LayoutWrapper(DetalheClientes)} />
                    <PrivateRoute exact path='/permissoes' component={LayoutWrapper(Administradores)} />
                    <PrivateRoute exact path='/tecnicos' component={LayoutWrapper(Tecnicos)} />
                    <PrivateRoute exact path='/relatorios' component={LayoutWrapper(Relatorios)} />
                    <Redirect from='*' to='/dashboard' />
                </Switch>
            </Router>
        )
    }
}
export default Routes