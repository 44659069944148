import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import {Container} from "react-bootstrap";
import Tickets from "./Tickets";
import '../../assets/css/components/menu.css';

const MenuTicket = () => {

    const [field, setField] = useState(0);

    const menuItens = [
        {
          name: "Chamados Novos",
          icon: ""
        },
        {
          name: "Chamados Pendentes",
          icon: ""
        },
        {
          name: "Chamados Finalizados",
          icon: ""
        }
      ]

    function handleChangeField(event){
        setField(parseInt(event.target.value))
    }

    return (
        <div>
            <Container fluid>
                <h6 className="text-secondary mb-3">Chamados</h6>
                <div className="menu shadow mb-3">
                    {
                        menuItens.map((item, index) => {
                           return(
                            <button 
                                className={ field === index ? "menuItem-selected": "menuItem"}
                                value={index}
                                onClick={handleChangeField}
                            >
                                {item.name}
                            </button>
                           )
                        })
                    }
                </div>

                <div>
                    { field === 0 && <Tickets status="novos"/>}
                    { field === 1 && <Tickets status="pendente"/>}
                    { field === 2 && <Tickets status="finalizado"/>}
                </div>

            </Container>
        </div>
    )
}

export default withRouter(MenuTicket)
