import React, { Component } from "react";
import { withRouter } from "react-router";
import { ToastErrorGet, ToastWarning } from "../../components/Toast"
import Overlay from "../../components/Overlay";
import AsyncSelect from "../../components/AsyncSelect";
import { searchUser } from "../../util/auth";
import { getReportAtendimentos } from "../../util/ReportsAtendimentos"

import {
    Card,
    Container,
    Row,
    Col,
    Form,
    Button,
    ButtonGroup
} from "react-bootstrap";

class Atendimentos extends Component {
    constructor() {
        super()
        this.state = {
            isLoading: false,
            overlay: true,
            tecnico: "",
            dataInicial: "",
            dataFinal: "",
            status: "",
            pdf: ""
        }

        this.getData = this.getData.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.gerarRelatorio = this.gerarRelatorio.bind(this);
    }

    componentWillMount() {
        this.getData()
    }

    getData() {
        this.setState({
            overlay: false
        })
    }

    handleChange(event) {
        let name = event.target.name;
        this.setState({
            [name]: event.target.value
        });
    }

    loadTecnicos(inputValue, callback) {
        searchUser(inputValue,(error, data) => {
                if (error) {
                    ToastErrorGet()
                    console.log(error)
                    callback([])
                } else {
                    let filtro = data.filter(tecnico => {
                        return tecnico.groups[0] === "tecnico"
                    })
                    let tecnicos = filtro.map(tecnico => {
                        return {
                            value: tecnico.id,
                            label: tecnico.nome
                        }
                    })
                    callback(tecnicos)
                }
            }
        )
    }

    gerarRelatorio(){

        this.setState({overlay: true})

        const data = {
            tecnico: this.state.tecnico && this.state.tecnico.value,
            dataInicial: this.state.dataInicial > "1000-01-01" && this.state.dataInicial,
            dataFinal: this.state.dataFinal,
            status: this.state.status
        }

        let { dataInicial, dataFinal} = data;

        if(dataInicial && dataFinal){
            dataInicial = new Date(dataInicial)
            dataFinal = new Date(dataFinal)

            if(dataInicial > dataFinal){
                this.setState({overlay:false})
                return ToastWarning("A data inicial é maior que a data final")
            }
        }

        if(!data.tecnico){
            data.tecnico = ""
        }

        getReportAtendimentos(data.tecnico,data.dataInicial,data.dataFinal,data.status,(data,error) => {
            if(error){
                ToastErrorGet()
                this.setState({ overlay: false})
            }else{
                this.setState({
                    pdf: data,
                    overlay: false
                })
            }
        })
    }

    render() {
        return (
            <div>
                <Container fluid>
                <Overlay loaded={this.state.overlay} />
                    <h6 className="text-secondary mb-4">Relátorios</h6>
                    <Card className="shadow">
                        <Card.Header className="border-0 bg-light">
                            <div className="d-flex justify-content-between align-items-center">
                                <h6 className="text-vermelho-bold m-0">RELATÓRIO DE ATENDIMENTOS</h6>
                            </div>
                        </Card.Header>
                        <Card.Body>
                            <form>
                                <Row>
                                    <Col xs={12} md={12} lg={12}>
                                        <Form.Group controlId="tecnico">
                                            <Form.Label>Selecione o Técnico</Form.Label>
                                            <AsyncSelect
                                                value={this.state.tecnico}
                                                onChange={this.handleChange}
                                                loadOptions={this.loadTecnicos}
                                                name="tecnico"
                                                placeholder="Pesquise por Codigo ou Nome"
                                                message="Pesquise o Técnico referente"
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} md={6} lg={6}>
                                        <Form.Group controlId="tecnico">
                                            <Form.Label>Data Inicial</Form.Label>
                                            <Form.Control
                                                type="date"
                                                name="dataInicial"
                                                onChange={this.handleChange}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={6} lg={6}>
                                        <Form.Group controlId="tecnico">
                                            <Form.Label>Data Final</Form.Label>
                                            <Form.Control
                                                type="date"
                                                name="dataFinal"
                                                onChange={this.handleChange}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} md={6} lg={6}>
                                        <label>Status</label><br />
                                        <ButtonGroup className="btn-group-toggle" data-toggle="buttons">
                                            <Button variant={this.state.status === "" ? "success": "light"} onClick={() => this.setState({ status: ""})}  size="sm">
                                                Todos
                                            </Button>
                                            <Button variant={this.state.status === "P" ? "success": "light"} onClick={() => this.setState({ status: "P"})} size="sm">
                                                Pendentes
                                            </Button>
                                            <Button variant={this.state.status === "F" ? "success": "light"} onClick={() => this.setState({ status: "F"})}  size="sm">
                                                Finalizados
                                            </Button>
                                        </ButtonGroup>
                                    </Col>
                                </Row>
                            </form>
                        </Card.Body>
                        <Card.Footer className="text-center">
                            <Button
                                variant="success"
                                size="lg"
                                onClick={this.gerarRelatorio}
                            >
                                Gerar Relatório
                            </Button>
                        </Card.Footer>
                    </Card>
                </Container>
            </div>
        )
    }
}
export default withRouter(Atendimentos)
