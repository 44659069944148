import axios from "axios"
import { defaultHeaders, makeRequestCreator } from "./Constants"

export function getClientes(callBack) {
  let config = {
    headers: defaultHeaders()
  }
  let url = process.env.REACT_APP_API + "cliente/"
  
  axios
    .get(url, config)
    .then(response => {
      callBack(response.data, null)
    })
    .catch(error => {
      callBack(null, error)
    })
}

export function getClienteById(pk,callBack) {
  let config = {
    headers: defaultHeaders()
  }
  let url = process.env.REACT_APP_API + `cliente/${pk}`
  
  axios
    .get(url, config)
    .then(response => {
      callBack(response.data, null)
    })
    .catch(error => {
      callBack(null, error)
    })
}

export function postCliente(params, callBack) {
  let config = {
    headers: defaultHeaders()
  }
  let url = process.env.REACT_APP_API + "cliente/"
  axios
    .post(url, params,config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}

export function putCliente(id, params, callBack) {
  let config = {
    headers: defaultHeaders()
  }
  let url = process.env.REACT_APP_API + `cliente/${id}/`
  axios
    .put(url, params, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}

const searchGet = makeRequestCreator()
export function searchCliente(searchText, callBack) {
  let url = 
    process.env.REACT_APP_API + 
    "cliente/?search=" + searchText

  return searchGet(url, callBack)
}