import {React} from 'react';
import Routes from './Routes';
import { ToastContainer } from 'react-toastify';

function App() {
  return (
    <div>
      <ToastContainer />
      <Routes/>
    </div>
  );
}

export default App;
