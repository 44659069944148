import React, { Component } from "react";

import {
    Container,
    Col,
    Spinner,
    Row
} from "react-bootstrap";

export default class LoadingContent extends Component {
    render() {
        return this.props.isLoading ? (
            <Container>
                <Row style={{ marginTop: "10" }}>
                    <Col lg={12} md={12} xs={12}>
                        <div className="d-flex justify-content-center"> 
                            <Spinner animation="border" variant="danger" />
                        </div>
                    </Col>
                </Row>
            </Container>
        ) : (
            this.props.children
        );
    }
}
