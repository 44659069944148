import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import InputMask from 'react-input-mask';
import { FaPlus, FaPaperPlane } from "react-icons/fa";
import { ToastErrorGet, ToastError, ToastSuccess } from "../../components/Toast"
import Overlay from "../../components/Overlay";
import { postCliente } from "../../util/Clientes";
import { getMunicipios, postMunicipio }from "../../util/Municipios"

import {
    Button,
    Card,
    Container,
    Modal,
    Col,
    Row,
    Form
} from "react-bootstrap";

class CreateClientes extends Component {
    constructor() {
        super()
        this.state = {
            showAdd: false,
            isLoading: false,
            overlay: false,
            municipios: [],
            cliente: {
                tipo_pessoa: "PF",
                cpf: "",
                cnpj: "",
                telefone1: "",
                telefone2: "",
                cep: ""
            }
        }
        this.getData = this.getData.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleSaveMunicipio = this.handleSaveMunicipio.bind(this);
    }

    componentDidMount(){
        this.getData()
    }

    setReset(){
        this.setState({
            cliente: {
                cpf: "",
                cnpj: "",
                telefone1: "",
                telefone2: "",
                cep: ""
            }
        })
        document.getElementById("createCliente").reset();
    }

    getData(){
        this.setState({overlay: true})
            getMunicipios((data, error) => {
                if (error) {
                    ToastErrorGet()
                } else {
                    this.setState({
                        municipios: data,
                        overlay: false
                    })
                }
            });
    }

    handleChange(event) {
        let name = event.target.name;
        this.setState({
            cliente: {
                ...this.state.cliente,
                [name]: event.target.value
            }
        });
    }

    handleSaveMunicipio(event){
        event.preventDefault()
        this.setState({overlay: true})
        let params = {
            nome: this.state.nomeMunicipio
        }
        postMunicipio(params,(error) => {
            if(error){
                ToastError('Ocorreram erros, verifique o formulário e tente novamente')
                this.setState({overlay: false})
            }
            else{
                ToastSuccess('Município cadastrado com sucesso')
                this.getData()
                this.setState({
                    overlay: false,
                    showAdd: false
                })
            }
        })
    }

    handleSubmit(event){
        event.preventDefault()
        this.setState({overlay: true})
        let params = this.state.cliente
        
        postCliente(params,(error) => {
            if(error){
                ToastError('Ocorreram erros, verifique o formulário e tente novamente')
                this.setState({overlay: false})
            }
            else{
                ToastSuccess('Cliente cadastrado com sucesso')
                this.setReset()
                this.setState({
                    overlay: false
                })
            }
        })
    }

    render() {
        return (
            <div>
                <Container fluid>
                <Overlay loaded={this.state.overlay} />
                    <h6 className="text-secondary mb-4">Clientes</h6>
                    <Card className="shadow">
                        <Card.Header className="bg-danger">
                            <div className="d-flex justify-content-between align-items-center">
                                <h6 className="text-light m-0">CADASTRAR CLIENTE</h6>
                            </div>
                        </Card.Header>
                        <Card.Body>
                            <form onSubmit={this.handleSubmit} name="createCliente" id="createCliente">
                            <Row>
                                <Col xs={12} md={4} lg={4}>
                                    <Form.Group controlId="nomeCliente">
                                        <Form.Label>Nome/Razão Social</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="nome_razao_social"
                                            onChange={this.handleChange}
                                            placeholder="Nome ou razão social do Cliente"
                                            required
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={4} lg={4}>
                                    <Form.Group controlId="tipoPessoa">
                                        <Form.Label>Tipo de Pessoa</Form.Label>
                                            <Form.Control as="select" name="tipo_pessoa" onChange={this.handleChange}>
                                                <option value="PF">Pessoa Fisica</option>
                                                <option value="PJ">Pessoa Jurídica</option>
                                            </Form.Control>
                                    </Form.Group>
                                </Col>
                                {this.state.cliente.tipo_pessoa === "PF" ? (
                                    <Col xs={12} md={4} lg={4}>
                                        <Form.Group controlId="cpfCliente">
                                            <Form.Label>CPF</Form.Label>
                                            <InputMask onChange={this.handleChange} value={this.state.cliente.cpf}  mask="999.999.999-99">
                                                {() => <Form.Control type="text" name="cpf" placeholder="CPF do Cliente" />}
                                            </InputMask>
                                        </Form.Group>
                                </Col>
                                ):(
                                    <Col xs={12} md={4} lg={4}>
                                        <Form.Group controlId="cnpjCliente">
                                            <Form.Label>CNPJ</Form.Label>
                                            <InputMask onChange={this.handleChange} value={this.state.cliente.cnpj} mask="99.999.999/9999-99">
                                                {() => <Form.Control type="text" name="cnpj" placeholder="CNPJ do Cliente" />}
                                            </InputMask>
                                        </Form.Group>
                                    </Col>
                                )}                   
                            </Row>
                            <Row>
                                <Col xs={12} md={6} lg={6}>
                                    <Form.Group controlId="responsavel">
                                        <Form.Label>Responsavel</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="responsavel"
                                            onChange={this.handleChange}
                                            placeholder="Responsável"
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={6} lg={6}>
                                    <Form.Group controlId="emailCliente">
                                        <Form.Label>E-mail:</Form.Label>
                                        <Form.Control
                                            type="email"
                                            name="email"
                                            onChange={this.handleChange}
                                            placeholder="E-mail do Cliente"
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={6} lg={6}>
                                    <Form.Group controlId="telefone">
                                        <Form.Label>Telefone 01</Form.Label>
                                        <InputMask onChange={this.handleChange} value={this.state.cliente.telefone1}  mask="(99)99999-9999">
                                            {() => <Form.Control type="text" name="telefone1" placeholder="Telefone 01"/>}
                                        </InputMask>
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={6} lg={6}>
                                    <Form.Group controlId="telefone2">
                                        <Form.Label>Telefone 02</Form.Label>
                                        <InputMask onChange={this.handleChange} value={this.state.cliente.telefone2} mask="(99)99999-9999">
                                            {() => <Form.Control type="text" name="telefone2" placeholder="Telefone 02"/>}
                                        </InputMask>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={4} lg={4}>
                                    <Form.Group controlId="site">
                                        <Form.Label>Site</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="site"
                                            onChange={this.handleChange}
                                            placeholder="Site"
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={4} lg={4}>
                                    <Form.Group controlId="uf">
                                        <Form.Label>Estado</Form.Label>
                                        <select required class="form-control" name="uf" onChange={this.handleChange}>
                                            <option value="">---</option>
                                            <option value="AC">Acre</option>
                                            <option value="AL">Alagoas</option>
                                            <option value="AP">Amapá</option>
                                            <option value="AM">Amazonas</option>
                                            <option value="BA">Bahia</option>
                                            <option value="CE">Ceará</option>
                                            <option value="DF">Distrito Federal</option>
                                            <option value="ES">Espírito Santo</option>
                                            <option value="GO">Goiás</option>
                                            <option value="MA">Maranhão</option>
                                            <option value="MT">Mato Grosso</option>
                                            <option value="MS">Mato Grosso do Sul</option>
                                            <option value="MG">Minas Gerais</option>
                                            <option value="PA">Pará</option>
                                            <option value="PB">Paraíba</option>
                                            <option value="PR">Paraná</option>
                                            <option value="PE">Pernambuco</option>
                                            <option value="PI">Piauí</option>
                                            <option value="RJ">Rio de Janeiro</option>
                                            <option value="RN">Rio Grande do Norte</option>
                                            <option value="RS">Rio Grande do Sul</option>
                                            <option value="RO">Rondônia</option>
                                            <option value="RR">Roraima</option>
                                            <option value="SC">Santa Catarina</option>
                                            <option value="SP">São Paulo</option>
                                            <option value="SE">Sergipe</option>
                                            <option value="TO">Tocantins</option>
                                        </select>
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={4} lg={4}>
                                    <Form.Group controlId="municipio">
                                        <Form.Label className="d-flex align-items-center">
                                            Município
                                            <FaPlus 
                                                onClick={() => this.setState({showAdd: true})}
                                                className="ml-2 icone-botao text-success"
                                            />
                                        </Form.Label>
                                        <select required class="form-control" name="municipio" onChange={this.handleChange}>
                                            <option value="">---</option>
                                            {(this.state.municipios || []).map((municipio, key) =>{
                                                return (
                                                    <option value={municipio.id}>{municipio.nome}</option>
                                                )
                                            })}
                                        </select>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={4} lg={4}>
                                    <Form.Group controlId="logradouro">
                                        <Form.Label>Logradouro</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="logradouro"
                                            onChange={this.handleChange}
                                            placeholder="logradouro"
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={4} lg={4}>
                                    <Form.Group controlId="bairro">
                                        <Form.Label>Bairro</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="bairro"
                                            onChange={this.handleChange}
                                            placeholder="bairro"
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={4} lg={4}>
                                    <Form.Group controlId="cep">
                                        <Form.Label>CEP</Form.Label>
                                        <InputMask onChange={this.handleChange} value={this.state.cliente.cep} mask="99.999-999">
                                            {() => <Form.Control type="text" name="cep" placeholder="Cep"/>}
                                        </InputMask>
                                    </Form.Group>
                                </Col>
                            </Row>
                            </form>
                        </Card.Body>
                        <Card.Footer>
                            <Button 
                                type="submit"
                                variant="success"
                                className="d-flex align-items-center float-right"
                                form="createCliente"
                            >
                                <FaPaperPlane className="mr-2"/> Cadastrar
                            </Button>
                        </Card.Footer>
                    </Card>
                </Container>
                {/* Modal Para Adicionar Municipio */}
                <Modal show={this.state.showAdd} onHide={() => this.setState({ showAdd: false })}>
                    <Modal.Header className="bg-success p-3" closeButton>
                        <h5 className="text-light mb-0">Cadastrar novo Município</h5>
                    </Modal.Header>
                    <Modal.Body>
                        <form onSubmit={this.handleSaveMunicipio} name="createMunicipio" id="createMunicipio">
                            <Col xs={12} md={12} lg={12}>
                                <Form.Group controlId="nomemunicipio">
                                    <Form.Label>Nome do Municipio</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="nome"
                                        onChange={(e) => this.setState({nomeMunicipio: e.target.value})}
                                        placeholder="Nome..."
                                    />
                                </Form.Group>
                            </Col>
                        </form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" size="sm" onClick={() => this.setState({ showAdd: false })}>
                            Close
                        </Button>
                        <Button 
                            type="submit"
                            variant="success"
                            className="d-flex align-items-center float-right"
                            form="createMunicipio"
                            size="sm"
                        >
                            <FaPaperPlane className="mr-2"/> Cadastrar
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}
export default withRouter(CreateClientes)
