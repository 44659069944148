import axios from "axios"
import { defaultHeaders } from "./Constants"

export function getMunicipios(callBack) {
    let config = {
      headers: defaultHeaders()
    }
    let url = process.env.REACT_APP_API + "municipio/"
    
    axios
      .get(url, config)
      .then(response => {
        callBack(response.data, null)
      })
      .catch(error => {
        callBack(null, error)
      })
  }

  export function getMunicipioById(pk, callBack) {
    let config = {
      headers: defaultHeaders()
    }
    let url = process.env.REACT_APP_API + `municipio/${pk}`
    
    axios
      .get(url, config)
      .then(response => {
        callBack(response.data, null)
      })
      .catch(error => {
        callBack(null, error)
      })
  }
  
  export function postMunicipio(params, callBack) {
    let config = {
      headers: defaultHeaders()
    }
    let url = process.env.REACT_APP_API + "municipio/"
    axios
      .post(url, params,config)
      .then(response => {
        callBack(undefined, response.data)
      })
      .catch(error => {
        callBack(error)
      })
  }