import React from "react";
// import { Link } from 'react-router-dom'
import {
    // Card,
    // Container,
    // Col,
    // Row,
} from "react-bootstrap";

import "../assets/css/components/cardDeStatus.css"

const CardDeStatus = ({color, icone, titulo, texto}) => {
    return (
        <div>
            <div class={`card card-de-status shadow borda-${color} mb-4`}>
                <div class="card-body d-flex justify-content-between align-items-center">
                    <div>
                        <span className={`titulo text-${color}`}>{titulo}</span>
                        <h5 className="m-0 text-gray-800"><strong>{texto}</strong></h5>
                    </div>
                    <div>
                        <span className="icone-card">{icone}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CardDeStatus
