import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import InputMask from 'react-input-mask';
import { FaEdit } from "react-icons/fa";
import LoadingContent from "../../components/LoadingContent";
import { ToastErrorGet, ToastError, ToastSuccess } from "../../components/Toast"
import Overlay from "../../components/Overlay";
import { getClienteById, putCliente } from "../../util/Clientes";
import { getMunicipios }from "../../util/Municipios";

import {
    Button,
    Card,
    Container,
    Modal,
    Col,
    Row,
    Form
} from "react-bootstrap";

class DetalheCliente extends Component {
    constructor() {
        super()
        this.state = {
            showEdit: false,
            isLoading: false,
            overlay: false,
            municipios: [],
            cliente: {
                tipo_pessoa: "PF",
                cpf: "",
                cnpj: "",
                telefone1: "",
                telefone2: "",
                cep: ""
            }
        }

        this.getData = this.getData.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleEdit = this.handleEdit.bind(this)
    }

    componentWillMount() {
        this.getData()
    }

    getData() {
        this.setState({ isLoading: true })
        getClienteById(this.props.match.params.id, (data, error) => {
            if (error) {
                ToastErrorGet()
            } else {
                this.setState({
                    cliente: data,
                    isLoading: false
                })
            }
        });
        getMunicipios((data, error) => {
            if (error) {
                ToastErrorGet()
            } else {
                this.setState({
                    municipios: data,
                    overlay: false
                })
            }
        });
    }

    handleChange(event) {
        let name = event.target.name;
        this.setState({
            cliente: {
                ...this.state.cliente,
                [name]: event.target.value,
            }
        });
    }

    handleEdit(event){
       event.preventDefault()
       this.setState({overlay: true})
       let params = this.state.cliente
       
       putCliente(params.id,params,(error) => {
           if(error){
               ToastError('Ocorreram erros, verifique o formulário e tente novamente')
               this.setState({overlay: false})
           }
           else{
               ToastSuccess('Cliente editado com sucesso')
               this.setState({
                   showEdit: false,
                   overlay: false
               })
           }
       })
    }

    render() {
        return (
            <div>
                <Container fluid>
                    <Overlay loaded={this.state.overlay} />
                    <h6 className="text-secondary mb-4">Técnicos</h6>
                    <Card className="shadow">
                        <Card.Header className="border-0 bg-light">
                            <div className="d-flex justify-content-between align-items-center">
                                <h6 className="text-vermelho-bold m-0">DETALHE DO CLIENTE: {this.state.cliente.id}</h6>
                                <Button
                                    variant="warning"
                                    onClick={() => this.setState({ showEdit: true })}
                                    className="d-flex align-items-center"
                                >
                                    <FaEdit className="mr-2"/> Alterar
                                </Button>
                            </div>
                        </Card.Header>
                        <Card.Body>
                            <LoadingContent isLoading={this.state.isLoading}>
                                <div className="text-center">
                                    <Row>
                                        <Col xs={12} md={6}>
                                            <strong>Nome/Razão Social</strong>
                                            <p>{this.state.cliente.nome_razao_social}</p>
                                            <strong>Tipo de Pessoa</strong>
                                            <p>{this.state.cliente.tipo_pessoa}</p>
                                            <strong>CNPJ/CPF</strong>
                                            <p>{this.state.cliente.tipo_pessoa === "PJ" ? this.state.cliente.cnpj : this.state.cliente.cpf}</p>
                                            <strong>Responsável</strong>
                                            <p>{this.state.cliente.responsavel}</p>
                                            <strong>E-mail</strong>
                                            <p>{this.state.cliente.email}</p>
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <strong>Telefone 01</strong>
                                            <p>{this.state.cliente.telefone1}</p>
                                            <strong>Telefone 02</strong>
                                            <p>{this.state.cliente.telefone2}</p>
                                            <strong>UF</strong>
                                            <p>{this.state.cliente.uf}</p>
                                            <strong>Município</strong>
                                            <p>{this.state.cliente.municipio}</p>
                                            <strong>Site</strong>
                                            <p>{this.state.cliente.site || "Não definido"}</p>

                                        </Col>
                                    </Row>
                                </div>
                            </LoadingContent>
                        </Card.Body>
                    </Card>
                </Container>
                {/* Modal Para Visualizacao */}
                <Modal
                    show={this.state.showEdit}
                    size="xl"
                    onHide={() => this.setState({ showEdit: false })}
                    backdrop="static"
                >
                    <Modal.Header className="bg-warning p-2" closeButton>
                        <Modal.Title className="text-light">Alterar Cliente: {this.state.cliente.id}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form onSubmit={this.handleEdit} name="editCliente" id="editCliente">
                            <Row>
                                <Col xs={12} md={4} lg={4}>
                                    <Form.Group controlId="nomeCliente">
                                        <Form.Label>Nome/Razão Social</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="nome_razao_social"
                                            value={this.state.cliente.nome_razao_social}
                                            onChange={this.handleChange}
                                            placeholder="Nome ou razão social do Cliente"
                                            required
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={4} lg={4}>
                                    <Form.Group controlId="tipoPessoa">
                                        <Form.Label>Tipo de Pessoa</Form.Label>
                                        <Form.Control as="select" name="tipo_pessoa" value={this.state.cliente.tipo_pessoa} onChange={this.handleChange}>
                                            <option value="PF">Pessoa Fisica</option>
                                            <option value="PJ">Pessoa Jurídica</option>
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                                {this.state.cliente.tipo_pessoa === "PF" ? (
                                    <Col xs={12} md={4} lg={4}>
                                        <Form.Group controlId="cpfCliente">
                                            <Form.Label>CPF</Form.Label>
                                            <InputMask onChange={this.handleChange} value={this.state.cliente.cpf} mask="999.999.999-99">
                                                {() => <Form.Control type="text" name="cpf" placeholder="CPF do Cliente" />}
                                            </InputMask>
                                        </Form.Group>
                                    </Col>
                                ) : (
                                    <Col xs={12} md={4} lg={4}>
                                        <Form.Group controlId="cnpjCliente">
                                            <Form.Label>CNPJ</Form.Label>
                                            <InputMask onChange={this.handleChange} value={this.state.cliente.cnpj} mask="99.999.999/9999-99">
                                                {() => <Form.Control type="text" name="cnpj" placeholder="CNPJ do Cliente" />}
                                            </InputMask>
                                        </Form.Group>
                                    </Col>
                                )}
                            </Row>
                            <Row>
                                <Col xs={12} md={6} lg={6}>
                                    <Form.Group controlId="responsavel">
                                        <Form.Label>Responsavel</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="responsavel"
                                            value={this.state.cliente.responsavel}
                                            onChange={this.handleChange}
                                            placeholder="Responsável"
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={6} lg={6}>
                                    <Form.Group controlId="emailCliente">
                                        <Form.Label>E-mail:</Form.Label>
                                        <Form.Control
                                            type="email"
                                            name="email"
                                            value={this.state.cliente.email}
                                            onChange={this.handleChange}
                                            placeholder="E-mail do Cliente"
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={6} lg={6}>
                                    <Form.Group controlId="telefone">
                                        <Form.Label>Telefone 01</Form.Label>
                                        <InputMask onChange={this.handleChange} value={this.state.cliente.telefone1} mask="(99)99999-9999">
                                            {() => <Form.Control type="text" name="telefone1" placeholder="Telefone 01" />}
                                        </InputMask>
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={6} lg={6}>
                                    <Form.Group controlId="telefone2">
                                        <Form.Label>Telefone 02</Form.Label>
                                        <InputMask onChange={this.handleChange} value={this.state.cliente.telefone2} mask="(99)99999-9999">
                                            {() => <Form.Control type="text" name="telefone2" placeholder="Telefone 02" />}
                                        </InputMask>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={4} lg={4}>
                                    <Form.Group controlId="site">
                                        <Form.Label>Site</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="site"
                                            value={this.state.cliente.site}
                                            onChange={this.handleChange}
                                            placeholder="Site"
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={4} lg={4}>
                                    <Form.Group controlId="uf">
                                        <Form.Label>Estado</Form.Label>
                                        <select required class="form-control" name="uf" value={this.state.cliente.uf} onChange={this.handleChange}>
                                            <option value="">---</option>
                                            <option value="AC">Acre</option>
                                            <option value="AL">Alagoas</option>
                                            <option value="AP">Amapá</option>
                                            <option value="AM">Amazonas</option>
                                            <option value="BA">Bahia</option>
                                            <option value="CE">Ceará</option>
                                            <option value="DF">Distrito Federal</option>
                                            <option value="ES">Espírito Santo</option>
                                            <option value="GO">Goiás</option>
                                            <option value="MA">Maranhão</option>
                                            <option value="MT">Mato Grosso</option>
                                            <option value="MS">Mato Grosso do Sul</option>
                                            <option value="MG">Minas Gerais</option>
                                            <option value="PA">Pará</option>
                                            <option value="PB">Paraíba</option>
                                            <option value="PR">Paraná</option>
                                            <option value="PE">Pernambuco</option>
                                            <option value="PI">Piauí</option>
                                            <option value="RJ">Rio de Janeiro</option>
                                            <option value="RN">Rio Grande do Norte</option>
                                            <option value="RS">Rio Grande do Sul</option>
                                            <option value="RO">Rondônia</option>
                                            <option value="RR">Roraima</option>
                                            <option value="SC">Santa Catarina</option>
                                            <option value="SP">São Paulo</option>
                                            <option value="SE">Sergipe</option>
                                            <option value="TO">Tocantins</option>
                                        </select>
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={4} lg={4}>
                                    <Form.Group controlId="municipio">
                                        <Form.Label className="d-flex align-items-center">Município</Form.Label>
                                        <select required class="form-control" name="municipio" value={this.state.cliente.municipio} onChange={this.handleChange}>
                                            <option value="">---</option>
                                            {(this.state.municipios || []).map((municipio, key) => {
                                                return (
                                                    <option value={municipio.id}>{municipio.nome}</option>
                                                )
                                            })}
                                        </select>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={4} lg={4}>
                                    <Form.Group controlId="logradouro">
                                        <Form.Label>Logradouro</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="logradouro"
                                            value={this.state.cliente.logradouro}
                                            onChange={this.handleChange}
                                            placeholder="logradouro"
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={4} lg={4}>
                                    <Form.Group controlId="bairro">
                                        <Form.Label>Bairro</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="bairro"
                                            value={this.state.cliente.bairro}
                                            onChange={this.handleChange}
                                            placeholder="bairro"
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={4} lg={4}>
                                    <Form.Group controlId="cep">
                                        <Form.Label>CEP</Form.Label>
                                        <InputMask onChange={this.handleChange} value={this.state.cliente.cep} mask="99.999-999">
                                            {() => <Form.Control type="text" name="cep" placeholder="Cep" />}
                                        </InputMask>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.setState({ showEdit: false })}>
                            Close
                        </Button>
                        <Button 
                            type="submit"
                            variant="warning"
                            className="d-flex align-items-center float-right"
                            form="editCliente"
                        >
                            <FaEdit className="mr-2"/> Alterar
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}
export default withRouter(DetalheCliente)
