import React from 'react';
import {
    Form
} from "react-bootstrap";
import useDebounce from "../hooks/userDebounce"

const InputSearch = ({label,placeholder,onChange, borda}) => {

    const debounceChange = useDebounce(onChange, 500)

    function handleChange(event){
        debounceChange(event.target.value)
    }

    return(
        <>
            <Form.Label>{label}</Form.Label>
            <Form.Control
                type="text"
                name="pesquisa"
                placeholder={placeholder}
                onChange={handleChange}
                className={`border ${borda}`}
            />
        </>
    )
}

export default InputSearch;