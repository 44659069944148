import React, { Component } from "react";
import LoadingOverlay from "react-loading-overlay";

import {Spinner} from "react-bootstrap";

export default class Overlay extends Component {
    render() {
        return(
        <div className="overlayLoading" style={this.props.loaded ? { display: "block" } : { display: "none" }}>
            <div className="content">
                <LoadingOverlay
                    active={true}
                    spinner={<Spinner animation="border" variant="light" />}
                >
                </LoadingOverlay>
            </div>
        </div>
        )
    }
}
