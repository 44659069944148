import React, { Component } from "react";
import { withRouter } from "react-router";
import { FaPlus, FaEye, FaPaperPlane } from "react-icons/fa";
import LoadingContent from "../../components/LoadingContent";
import { ToastErrorGet, ToastError, ToastSuccess } from "../../components/Toast"
import Overlay from "../../components/Overlay";
import { registro, getUsers } from '../../util/auth'

import {
    Button,
    Card,
    Table,
    Container,
    Modal,
    Form
} from "react-bootstrap";

class Administradores extends Component {
    constructor() {
        super()
        this.state = {
            showView: false,
            showAdd: false,
            isLoading: false,
            overlay: false,
            users: [],
            userSelected: {}
        }

        this.getData = this.getData.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSave = this.handleSave.bind(this);
    }

    componentWillMount() {
        this.getData()
    }

    getData() {
        this.setState({isLoading: true})
        getUsers((data, error) => {
            if (error) {
                ToastErrorGet()
            } else {
                this.setState({
                    users: data,
                    isLoading: false
                })
            }
        });
    }

    handleChange(event) {
        let name = event.target.name;
        this.setState({
        [name]: event.target.value,
        });
    }

    handleSave(event){
        event.preventDefault();
        this.setState({overlay: true})
        let params = {
            email: this.state.email,
            password: this.state.password,
            nome: this.state.username
        }
        
        registro(params,(error) => {
            if(error){
                ToastError('Ocorreram erros, verifique o formulário e tente novamente')
                this.setState({overlay: false})
            }
            else{
                ToastSuccess('Usuário com permissão registrado!')
                this.getData()
                this.setState({
                    showAdd: false,
                    overlay: false
                })
            }
        })
    }

    render() {
        return (
            <div>
                <Container fluid>
                <Overlay loaded={this.state.overlay} />
                    <h6 className="text-secondary mb-4">Permissões</h6>
                    <Card className="shadow">
                        <Card.Header className="bg-danger">
                            <div className="d-flex justify-content-between align-items-center">
                                <h6 className="text-light m-0">AUTENTICAÇÃO E AUTORIZAÇÃO</h6>
                                <button className="btn btn-branco d-flex align-items-center" onClick={() => this.setState({ showAdd: true })}><FaPlus className="mr-2" /> Cadastrar</button>
                            </div>

                        </Card.Header>
                        <Card.Body>
                            <LoadingContent isLoading={this.state.isLoading}>
                                <Table hover responsive>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Email</th>
                                            <th>Nome</th>
                                            <th>Ações</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {   
                                            this.state.users.map((user, key) =>{
                                                return(
                                                    <tr key={key}>
                                                        <td>{user.id}</td>
                                                        <td>{user.email}</td>
                                                        <td>{user.nome}</td>
                                                        <td>
                                                            <Button  className="mr-2" size="sm" onClick={() => this.setState({showView: true, userSelected: user})}><FaEye /></Button>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </Table>
                            </LoadingContent>
                        </Card.Body>
                    </Card>
                </Container>
                {/* Modal Para Visualizacao */}
                <Modal show={this.state.showView} onHide={() => this.setState({ showView: false })}>
                    <Modal.Header className="bg-danger" closeButton>
                        <Modal.Title className="text-light">Detalhe do Usuário: {this.state.userSelected.id}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="text-center">
                            <strong>Email</strong>
                            <p>{this.state.userSelected.email}</p>
                            <strong>Nome de Usuário</strong>
                            <p>{this.state.userSelected.nome}</p>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.setState({ showView: false })}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>

                {/* Modal Para Adição */}
                <Modal
                    show={this.state.showAdd}
                    onHide={() => this.setState({ showAdd: false })}
                    backdrop="static">
                    <Modal.Header className="bg-danger" closeButton>
                        <Modal.Title className="text-light">Adicionar nova Permissão</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form onSubmit={this.handleSave} name="createPermissao" id="createPermissao">
                            <Form.Group controlId="emailfield">
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                    type="email"
                                    name="email"
                                    onChange={this.handleChange}
                                    placeholder="Informe o email..."
                                    required
                                />
                            </Form.Group>
                            <Form.Group controlId="usuariofield">
                                <Form.Label>Nome do Usuário</Form.Label>
                                <Form.Control
                                    type="text" 
                                    name="username"
                                    onChange={this.handleChange}
                                    placeholder="Informe o nome de usuário..." 
                                    required
                                />
                            </Form.Group>
                            <Form.Group controlId="password">
                                <Form.Label>Senha</Form.Label>
                                <Form.Control
                                    type="password" 
                                    name="password"
                                    onChange={this.handleChange}
                                    placeholder="Informe a senha..." 
                                    required
                                />
                            </Form.Group>
                        </form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.setState({ showAdd: false })}>
                            Close
                        </Button>
                        <Button variant="success" type="submit" form="createPermissao" className=" d-flex align-items-center">
                            <FaPaperPlane className="mr-2" /> Salvar
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}
export default withRouter(Administradores)
