import React, { Component } from "react";
import { withRouter } from "react-router";
import { FaPaperPlane } from "react-icons/fa";
import { ToastErrorGet, ToastError, ToastSuccess } from "../../components/Toast";
import Overlay from "../../components/Overlay";
import { login, me } from '../../util/auth'
import {
    Container,
    Form,
    Col,
    Card
} from "react-bootstrap";

import "../../assets/css/views/login.css"
import HeroImage from "../../assets/img/hero-image.svg"

class Login extends Component {
    constructor(props) {
        super(props)
        this.state = {
            overlay: false,
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

    }

    handleSubmit(e) {
        e.preventDefault();
        this.setState({ overlay: true })

        let params = {
            email: this.state.email,
            password: this.state.senha
        }

        login(params, (error) => {
            if (error) {
                ToastError('Credenciais invalidas')
                this.setState({ overlay: false })
            } else {
                ToastSuccess('Logado com Sucesso')
                this.setState({
                    overlay: false,
                })
                me((data, error) => {
                    if (error) {
                        ToastErrorGet()
                    } else {
                        this.props.history.push("/dashboard")
                    }
                });
            }
        })
    }

    handleChange(event) {
        let name = event.target.name;
        this.setState({
            [name]: event.target.value,
        });
    }

    render() {
        return (
            <div className="background">
                <Container >
                    <Card className="border-0 mt-10">
                        <Card.Body>
                            <div className="row">
                                <Col xs={12} md={12} lg={6}>
                                    <div className="imagem-cover px-4">
                                        <img src={HeroImage} className="img-login" alt="Imagem de login" />
                                    </div>
                                </Col>
                                <Col xs={12} md={12} lg={6}>
                                    <Overlay loaded={this.state.overlay} />
                                    <Container>
                                    <Card className="border-0">
                                        <div class="card-header text-center bg-light pb-0 text-gray-800">
                                            <h5>ALUCOM SISTEMA DE ORDEM DE SERVIÇO</h5>
                                            <h6>Insira suas credenciais</h6>
                                        </div>
                                        <Card.Body>
                                            <Form onSubmit={this.handleSubmit}>
                                                <Form.Group controlId="formBasicEmail">
                                                    <Form.Label>E-mail</Form.Label>
                                                    <Form.Control
                                                        type="email"
                                                        name="email"
                                                        placeholder="Digite seu e-mail..."
                                                        onChange={this.handleChange}
                                                        required
                                                    />
                                                </Form.Group>
                                                <Form.Group controlId="formBasicPassword">
                                                    <Form.Label>Senha</Form.Label>
                                                    <Form.Control
                                                        type="password"
                                                        name="senha"
                                                        placeholder="Digite a sua senha..."
                                                        onChange={this.handleChange}
                                                        required
                                                    />
                                                </Form.Group>
                                                <div className="mt-4">
                                                    <button type="submit" className="btn btn-vermelho-outline btn-block d-flex align-items-center justify-content-center">
                                                        <FaPaperPlane className="mr-2" /> Entrar
                                                    </button>
                                                </div>
                                                
                                            </Form>
                                            <div class="text-center mt-4 text-gray-800">
                                                <p>© 2021 <span className="text-azul">A.S Sistemas.</span></p>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                    </Container>
                                </Col>
                            </div>
                        </Card.Body>
                    </Card>
                </Container>
            </div>
        )
    }
}

export default withRouter(Login)
