import React, { Component } from "react";
import { withRouter } from "react-router";
import { FaThumbtack, FaHourglassStart, FaClipboardCheck } from "react-icons/fa";
import Overlay from "../components/Overlay";
import CardDeStatus from "../components/CardDeStatus";
import { getDashboard } from "../util/Dashboard"

import {
    Container,
    Col,
    Row,
    Card,
} from "react-bootstrap";
import { ToastErrorGet } from "../components/Toast";
import ApexChart from "../components/Charts/ApexChart";

class Dashboard extends Component {
    constructor() {
        super()
        this.state = {
            isLoading: true,
            overlay: false,
            dashboard: {}
        }

        this.getData = this.getData.bind(this);
    }

    componentWillMount() {
        this.getData()
    }

    getData() {
        this.setState({overlay: true})
        getDashboard((data,error) =>{
            if(error){
                ToastErrorGet()
                this.setState({overlay: false})
            }else{
                this.setState({
                    dashboard: data,
                    overlay: false
                })
            }
        })
    }

    render() {
        const me = JSON.parse(localStorage.me)
        return (
            <div>
                <Container fluid>
                    <Overlay loaded={this.state.overlay} />
                    <h6 className="text-secondary mb-4"><strong>Bem vindo, {me.email}</strong></h6>
                    <Row>
                        <Col xs={12} xl={4} md={6}>
                            <CardDeStatus
                                color="vermelha"
                                icone={<FaThumbtack />}
                                titulo="Novas Ordens de serviço"
                                texto={this.state.dashboard.tickets_novos || "0"}
                            />
                        </Col>
                        <Col xs={12} xl={4} md={6}>
                            <CardDeStatus
                                color="vermelha"
                                icone={<FaHourglassStart />}
                                titulo="Ordens de serviço pendentes"
                                texto={this.state.dashboard.tickets_pendentes || "0"}
                            />
                        </Col>
                        <Col xs={12} xl={4} md={6}>
                            <CardDeStatus
                                color="vermelha"
                                icone={<FaClipboardCheck />}
                                titulo="Ordens de serviço finalizadas"
                                texto={this.state.dashboard.tickets_finalizados || "0"}
                            />
                        </Col>
                    </Row>
                    <Col xs={12} xl={12} md={12}>
                        <Card className="shadow mb-4">
                            <Card.Header>
                                <div className="d-flex justify-content-between align-items-center">
                                    <h6 className="text-vermelho-bold m-0">ATENDIMENTO</h6>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <ApexChart type="line" height="250px" data={this.state.dashboard.atendimento_mes} color="#DC3545" />
                            </Card.Body>
                        </Card>
                    </Col>
                </Container>
            </div>
        )
    }
}

export default withRouter(Dashboard)

