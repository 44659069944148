import axios from "axios"
import { defaultHeaders, makeRequestCreator } from "./Constants"

export function getUsers(callBack) {
  let config = {
    headers: defaultHeaders()
  }
  let url = process.env.REACT_APP_API.split("core/").join("user/users/")
  
  axios
    .get(url, config)
    .then(response => {
      callBack(response.data, null)
    })
    .catch(error => {
      callBack(null, error)
    })
}

const searchGet = makeRequestCreator()
export function searchUser(searchText, callBack) {
  
  let url = process.env.REACT_APP_API.split("core/").join(`user/users/?search=${searchText}`)

  return searchGet(url, callBack)
}

export function login(params, callBack) {
  let url = process.env.REACT_APP_API.split("core/").join("user/token/")
  axios
    .post(url, params)
    .then(response => {
      localStorage.token = response.data.token
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}

export function me(callBack) {
  let config = {
    headers: defaultHeaders()
  }
  let url = process.env.REACT_APP_API.split("core/").join("user/me/")
  axios
    .get(url, config)
    .then(response => {
      localStorage.me = JSON.stringify(response.data)
      callBack(response.data, null)
    })
    .catch(error => {
      callBack(null, error)
    })
}

export function registro(params, callBack) {

  let url = process.env.REACT_APP_API.split("core/").join("user/create/")
  axios
    .post(url, params)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}

export const isAuthenticated = () => {
  console.log(localStorage.token !== null)
  return localStorage.token !== null;
};


export const logout = () => {
  delete localStorage.token;
};