import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { FaPaperPlane } from "react-icons/fa";
import { ToastErrorGet, ToastError, ToastSuccess } from "../../components/Toast"
import Overlay from "../../components/Overlay";
import AsyncSelect from "../../components/AsyncSelect";
import { searchUser } from "../../util/auth";
import { searchCliente } from "../../util/Clientes";
import { postTickets } from "../../util/Tickets";

import {
    Button,
    Card,
    Container,
    Col,
    Row,
    Form
} from "react-bootstrap";

class RegistrarTicket extends Component {
    constructor() {
        super()
        this.state = {
            isLoading: false,
            overlay: false,
            users: [],
            userSelected: {},
            cliente: "",
            tecnico: ""
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.loadClientes = this.loadClientes.bind(this);
        this.loadTecnicos = this.loadTecnicos.bind(this);
    }

    resetState(){
        this.setState({
            cliente: "",
            tecnico: "",
            servico: "",
            descricao: ""
        })
        document.getElementById("createTicket").reset();
    }

    handleChange(event) {
        let name = event.target.name;
        this.setState({
            [name]: event.target.value
        });
    }

    handleSave(event) {
        event.preventDefault()
        let params = {
            tecnico: this.state.tecnico.value,
            cliente: this.state.cliente.value,
            servico: this.state.servico,
            descricao: this.state.descricao
        }
        this.setState({overlay: true})

        postTickets(params,(error) => {
            if(error){
                ToastError('Ocorreram erros, verifique o formulário e tente novamente')
                this.setState({overlay: false})
            }
            else{
                ToastSuccess('Ticket registrado com sucesso')
                this.resetState();
                this.setState({overlay: false})
            }
        })
    }

    loadClientes(inputValue, callback) {
        searchCliente(inputValue,(error, data) => {
                if (error) {
                    ToastErrorGet()
                    console.log(error)
                    callback([])
                } else {
                    let clientes = (data.results || []).map(cliente => {
                        return {
                            value: cliente.id,
                            label: `${cliente.id} -- ${cliente.nome_razao_social} -- ${cliente.cpf ? cliente.cpf || "" : cliente.cnpj || ""}`
                        }
                    })
                    callback(clientes)
                }
            }
        )
    }

    loadTecnicos(inputValue, callback) {
        searchUser(inputValue,(error, data) => {
                if (error) {
                    ToastErrorGet()
                    console.log(error)
                    callback([])
                } else {
                    let filtro = data.filter(tecnico => {
                        return tecnico.groups[0] === "tecnico"
                    })
                    let tecnicos = filtro.map(tecnico => {
                        return {
                            value: tecnico.id,
                            label: tecnico.nome
                        }
                    })
                    callback(tecnicos)
                }
            }
        )
    }

    render() {
        return (
            <div>
                <Container fluid>
                <Overlay loaded={this.state.overlay} />
                    <h6 className="text-secondary mb-4">Tickets</h6>
                    <Card className="shadow">
                        <Card.Header className="border-0 bg-light">
                            <div className="d-flex justify-content-between align-items-center">
                                <h6 className="text-vermelho-bold m-0">REGISTAR NOVO TICKET</h6>
                            </div>
                        </Card.Header>
                        <Card.Body>
                            <form onSubmit={this.handleSave} id="createTicket" name="createTicket">
                                <Row>
                                    <Col xs={12} md={12} lg={12}>
                                        <Form.Group controlId="cliente">
                                            <Form.Label>Selecione o Cliente*</Form.Label>
                                            <AsyncSelect
                                                value={this.state.cliente}
                                                onChange={this.handleChange}
                                                loadOptions={this.loadClientes}
                                                name="cliente"
                                                placeholder="Pesquise por Nome/Razão, CPF/CNPJ, Responsável"
                                                message="Pesquise o Cliente referente"
                                                required
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={12} lg={12}>
                                        <Form.Group controlId="tecnico">
                                            <Form.Label>Selecione o Técnico*</Form.Label>
                                            <AsyncSelect
                                                value={this.state.tecnico}
                                                onChange={this.handleChange}
                                                loadOptions={this.loadTecnicos}
                                                name="tecnico"
                                                placeholder="Pesquise por Codigo ou Nome"
                                                message="Pesquise o Técnico referente"
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} md={12} lg={12}>
                                        <Form.Group controlId="servico">
                                            <Form.Label>Serviço*</Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                rows={3}
                                                name="servico"
                                                onChange={this.handleChange}
                                                placeholder="Serviço referente ao Ticket..."
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={12} lg={12}>
                                        <Form.Group controlId="descricao">
                                            <Form.Label>Descrição</Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                rows={3}
                                                name="descricao"
                                                onChange={this.handleChange}
                                                placeholder="Descrição"
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </form>
                        </Card.Body>
                        <Card.Footer>
                            <Button
                                type="submit"
                                form="createTicket"
                                variant="success"
                                className="d-flex align-items-center float-right"
                                disabled={this.state.cliente && this.state.tecnico ? false : true}
                            >
                                <FaPaperPlane className="mr-2"/> Cadastrar
                            </Button>
                        </Card.Footer>
                    </Card>
                </Container>
            </div>
        )
    }
}
export default withRouter(RegistrarTicket)
